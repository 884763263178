import React, { Component } from 'react';
import { ConsentFormModal } from 'tn-components-library';
import http from '../../http';

class ConsentForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            changeContent: true,
        }
        
    this.handleUserSelectedOption = this.handleUserSelectedOption.bind(this);
    this.updateConsentDetails = this.updateConsentDetails.bind(this)
    }
    updateConsentDetails(details){
        http.post(`${window.Gemini.Config.adminpublicserviceURL}/api/consent/`, details)
            .then((response) => {
              if(details.isTrackingAllowed && response.data){
                this.props.enableHotJarTracking()
              }
                console.log("User consent form updated")
            })
            .catch(err =>{
                    console.log(err);
                }
            )
        }
      handleUserSelectedOption(action){
        if(action=== 'back'){
          this.setState({changeContent: true})
        }
        if(action === 'next'){
          this.setState({changeContent: false})
        }
        if(action === 'accept' || action === 'decline'){
          this.props.handleConsentModal(false)
          this.updateConsentDetails({isTrackingAllowed: action === 'accept' ? true : false, version: this.props.consentDetails.version})
        }
      }
    render() {
        return (
            <ConsentFormModal
          size={'large'}
          open={this.props.consentModalOpen}
          duration={10}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          showBackdrop={true}
          handleUserSelectedOption = {this.handleUserSelectedOption}
        >{this.state.changeContent ? 
          <div dangerouslySetInnerHTML={{ __html:this.props.consentDetails?.termsOfUse}} />
              :   <div  dangerouslySetInnerHTML={{ __html:this.props.consentDetails?.privacyPolicy}} />
          }  
      </ConsentFormModal>
        );
    }
}

export default ConsentForm;